import { ErrorHandler, inject, Injectable } from '@angular/core';
import { TRACKER_SERVICE } from '../tracking';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private trackerService = inject(TRACKER_SERVICE);
  handleError(error: any) {
    this.trackerService.captureException(error);
    console.error(error);
  }
}
